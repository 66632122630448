import React from "react";

const footerStyle = {
    background: "#D4AF37",
    color: "white",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "auto",
    width: "100%"
};

export default function Footer({ children }) {
    return (
        <div>
            <div style={footerStyle}>{children}</div>
        </div>
    );
}