import { Grid, Button } from '@material-ui/core';
import React from 'react'
import NavBar from './NavBar';
import Footer from './Footer';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from "react-router-dom";

export default function Layout(props) {
    const history = useHistory();
    return (
        <>
            <Grid container key="Header">
                <Grid item md={12} sm={12} xs={12}>
                    <NavBar />
                </Grid>
            </Grid>
            <Grid container key="Body">
                <Grid item md={2} sm={12} xs={12}>
                </Grid>
                <Grid item md={8} sm={12} xs={12}>
                    {props.children}
                </Grid>
                <Grid item md={2} sm={12} xs={12}>
                </Grid>
            </Grid>
            <span style={{ position: "fixed", right: "20px", bottom: "80px", display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                <Fab aria-label="add" onClick={() => {history.push("/add_article")}} style={{background:"#D4AF37", marginBottom: "10px"}}>
                    <AddIcon/>
                </Fab>
                <Button style={{background:"#D4AF37",padding:"10px"}} onClick={() => {history.push("/manage_advertisement")}}>Manage Advertisement</Button>
            </span>
            <Grid container key="Footer">
                <Footer>
                    Copyright &copy; 2020 INewsTamil. All rights reserved.
                </Footer>
            </Grid>
        </>
    );
}