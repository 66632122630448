import firebase from 'firebase'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyAnROFewbrLFVW3dbOA3QzAJdrC8Af89z4",
    authDomain: "inewstamil.firebaseapp.com",
    databaseURL: "https://inewstamil.firebaseio.com",
    projectId: "inewstamil",
    storageBucket: "inewstamil.appspot.com",
    messagingSenderId: "782545522903",
    appId: "1:782545522903:web:7d3cab4b95a7c523486092",
    measurementId: "G-P5ZLCP0YK9"
};

const firebaseInst = firebase.initializeApp(firebaseConfig)

export const auth = firebaseInst.auth()

export const storage = firebase.storage()

export const firestore = firebase.firestore()
