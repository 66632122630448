import React,{useState, useEffect} from 'react'
import { Container, TextField, Grid, Button } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import {firestore} from './Firebase'
import {storage} from './Firebase'

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import Typography from '@material-ui/core/Typography';


function AddAdvertisement({open, handleClose}){
    let title = 'Add Advertisement'
    let action = 'Add'
    const [uploading, setUploading] = useState(false)
    const [name, setName] = useState('')
    const [priority, setPrioirty] = useState(0)
    const [ad, setAd] = useState({})
    const startUpload = async(e) => {
        e.preventDefault()
        console.log("Uploading")
        setUploading(true)
        const fileRef = storage.ref().child([new Date().getTime(),ad.type.split('/').slice(-1).pop()].join('.'))
        await fileRef.put(ad)
        const media_url = await fileRef.getDownloadURL()
        const adDoc = {'name': name, 'priority': priority, 'img': media_url}
        await firestore.collection('advertisement').doc().set(adDoc)
        setUploading(false);
        console.log("Done uploading")
        handleClose()
    }
    return (
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Advertisement</DialogTitle>
            <DialogContent>
                <TextField autoFocus
                    margin="dense"
                    id="name"
                    label="Advertisement Name"
                    type="text"
                    onChange={(e)=>{setName(e.target.value)}}
                    fullWidth 
                    />
                <TextField
                    margin="dense"
                    id="priority"
                    label="priority"
                    type="number"
                    onChange={(e)=>{setPrioirty(e.target.value)}}
                    fullWidth />
                <br/><br/>
                <Button  color='primary' variant='contained' fullwidth>
                    <input type="file" multiple onChange={(e)=>{setAd(e.target.files[0])}} />
                </Button>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color="primary" disabled={uploading}>
                Cancel
            </Button>
            <Button type="submit" color="primary" disabled={uploading} onClick={startUpload}>
                Add
            </Button>
            </DialogActions>
            </Dialog>
        </>
    );
}
export default function ManageAdvertisement() {
    const [open, setOpen] = useState(false)
    const [doc, setDoc] = useState({})
    const [ads, setAds] = useState([])
    const handleClose = () => { setOpen(false); fetch_ads() }
    
    const fetch_ads = () => {
        firestore.collection('advertisement').orderBy("priority","desc").get().then((collections)=>
        {
            const articles1 = collections.docs.map(doc => ({ adData: doc.data(), id: doc.id }))
            setAds([...articles1])
        })
    }
    const deleteAdDoc = async (doc)=> {
        console.log("Deletion started");
        const fileRef = await storage.refFromURL(doc.adData.img)
        await fileRef.delete()
        firestore.collection("advertisement").doc(doc.id).delete().then(()=>{
            console.log("File deleted")
        }).catch(()=>{
            console.log("Error in deletion")
        })
        fetch_ads()
    }
    useEffect(() => {
        fetch_ads()
        console.log(ads)
    }, [])
    return (
        <Container>
            <Container>
                {ads && ads.map((data, index) =>
                    <>
                    <br/>
                    <Card key={data.id}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        alt="Contemplative Reptile"
                        height="140"
                        image={data.adData.img}
                        title="Contemplative Reptile"
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          {data.adData.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Priority : {data.adData.priority}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions style={{float:"right"}}>
                      <Button size="small" color="primary" onClick={()=>{console.log("Hello");deleteAdDoc(data)}}>
                        Delete
                      </Button>
                    </CardActions>
                  </Card><br/>
                  </>
                )}
            </Container>
            <span style={{ position: "fixed", right: "20px", bottom:"80px"}}>
                <Fab aria-label="add" style={{background:"#D4AF37", marginBottom: "10px"}} onClick={()=>{setDoc({});setOpen(true);}}>
                    <AddIcon/>
                </Fab>
            </span>
            <AddAdvertisement open={open} handleClose={handleClose} doc={doc}/>
        </Container>
    )   
}
