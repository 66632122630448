import {Container, makeStyles, Typography, TextField, Button, Grid} from '@material-ui/core'
import {InputLabel, Select, FormHelperText, FormControl, MenuItem} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';  

import {useEffect, useState} from 'react'
import clsx from 'clsx';

import {useAuth} from './AuthContext'
import {firestore} from './Firebase'
import {storage} from './Firebase'
import AddCategory from './AddCategtory';

const useStyles = makeStyles((theme)=>({
    scaffold:
    {
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]:{
            padding: theme.spacing(3),
        }
    },
    paper:{
        marginTop:theme.spacing(5),
    },
    formItem:{
        marginTop:theme.spacing(5),
    },
    large: {
        width: theme.spacing(40),
        height: theme.spacing(30),
        [theme.breakpoints.down('sm')]:{
            marginTop: theme.spacing(1),
            width: theme.spacing(30),
            height: theme.spacing(30),
        }
    },
    uploadRoot: {
        display: 'flex',
        alignItems: 'center',
    },
    uploadWrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    uploadSuccess: {
        backgroundColor: green[500],
        '&:hover': {
        backgroundColor: green[700],
        },
    },
    uploadProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

export default function EditArticle({match})
{
    const classes = useStyles()
    const {deleteFromStorage} = useAuth()

    const [editorial, setEditorial] = useState({"title":"", category:"","banner":{"type":"", "url":""}, "fullstory":"", "gallery":[]}) //{articleid: "",title:"", bannerurl:"", fullstory: "", gallery:[]}
    const [localBanner, setLocalBanner] = useState({})
    const [localGallery, setLocalGallery] = useState([])
    const [loading, setLoading] = useState(true)
    const [uploading, setUploading] = useState(false)
    const [addNewcategory, setAddNewcategory] = useState(false)
    
    const [categories, setCategories] = useState([])

    const [uploadSuccess, setUploadSuccess] = useState  (false)
    
    let uploadTempObj = {}

    const uploadButtonClassname = clsx({
        [classes.uploadSuccess]: uploadSuccess,
    });
    
    const deleteMedia = () => {}

    const startUpload = async (e) => {
        e.preventDefault()
        setUploading(true)
        uploadMedia()
    }
    const uploadArticle = async(e) =>{
        if(match.params.article)
        {
            await firestore.collection('articles').doc(match.params.article).set(uploadTempObj)
            console.log("Article added")
        }
        else
        {
            await firestore.collection('articles').doc().set(uploadTempObj)
            console.log("Article added")
        }
        setUploading(false)
        setUploadSuccess(true)
    }

    const uploadMedia = async () => {
        uploadTempObj = {...editorial, createdAt: new Date().getTime()}
        if(localGallery.length != 0)
        {
            const localGalleryUrl = []
            for(let i=0;i<localGallery.length;i++)
            {
                const media = localGallery[i]
                const fileRef = storage.ref().child([new Date().getTime(),media.type.split('/').slice(-1).pop()].join('.'))
                await fileRef.put(media)
                const media_url = await fileRef.getDownloadURL()
                localGalleryUrl.push({"type":media.type,"url":media_url})
            }
            console.log("After map")
            console.log(localGalleryUrl)
            uploadTempObj = {...uploadTempObj,gallery:[...editorial.gallery,...localGalleryUrl]}
            console.log(uploadTempObj)
        }
        if(localBanner.type)
        {
            const fileRef = storage.ref().child([new Date().getTime(),localBanner.type.split('/').slice(-1).pop()].join('.'))
            await fileRef.put(localBanner)
            const banner_url = await fileRef.getDownloadURL()
            uploadTempObj = {...uploadTempObj,"banner":{"url":banner_url,"type":localBanner.type}}
            console.log(uploadTempObj)
        }
        uploadArticle()
    }

    async function fetch_article_data()
    {
        const data = await firestore.collection('articles').doc(match.params.article).get()
        setEditorial({...data.data()})
        setLoading(false)
    }

    const deleteMediaForBanner = async (storageUrl) =>{
        const fileRef = await storage.refFromURL(storageUrl)
        await fileRef.delete()
        setEditorial({...editorial, "banner":{"type":"", "url":""}})
        uploadArticle()      
    }

    const deleteFromLocalGallery = (index) =>{
        const tempGallery = localGallery
        tempGallery.splice(index,1)
        setLocalGallery([...tempGallery])
        console.log(tempGallery)
        console.log(localGallery.length)
    }  
    const deleteFromGallery = async (index) =>{
        const fileRef = storage.refFromURL(editorial.gallery[index].url)
        await fileRef.delete()
        let tempObj = editorial.gallery
        tempObj.splice(index,1)
        setEditorial({...editorial,gallery:[...tempObj]})
    } 

    const handleLocalGalleryChange = (e)=>
    {
        const tempArray = []
        for(let i=0;i<e.target.files.length;i++)
        {
            tempArray.push(e.target.files[i])
        }
        setLocalGallery(tempArray)
    }

    async function fetch_categories()
    {
        firestore.collection('categories').onSnapshot((docs)=>
        {
            docs.forEach(doc=>setCategories([...doc.data().type]))
        })
    }
    useEffect(()=>{
        fetch_categories()
        if(match.params.article != null)
        {
            fetch_article_data()
        }
        else
        {
            setLoading(false)
        }
    },[])

    const handleButtonClick = () => {
        if (!loading) {
            setUploadSuccess(false);
          setUploading(true);
          window.setTimeout(() => {
            setUploadSuccess(true);
            setUploading(false);
          }, 2000);
        }
      };

    return(
        <>
        { !loading && 
        (<Container maxWidth='md' className={classes.scaffold}>
            <Typography variant="h5" component="h5" align="center">Welcome to the Editorial</Typography>
            <div className={classes.paper}>

                <form onSubmit={startUpload}>
                    <TextField type="text" label='Title' variant='filled' fullWidth required value={editorial.title} onChange={(e)=>{setEditorial({...editorial,"title":e.target.value})}}/>
                                
                    <FormControl className={classes.formItem}>
                        <InputLabel id="category">Category</InputLabel>
                        <Select
                        labelId="category"
                        value={editorial.category}
                        onChange={(e)=>{setEditorial({...editorial,"category":e.target.value})}}
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {categories.map((category)=>
                            <MenuItem key={category} value={category}>{category}</MenuItem>
                        )}  
                        </Select>
                        <FormHelperText>Some important helper text</FormHelperText>
                    </FormControl>
                    &nbsp;&nbsp;&nbsp;
                    <Button className={classes.formItem} variant="outlined" color="primary" startIcon={<AddIcon/>} onClick={()=>{setAddNewcategory(true)}}>Add new category</Button>
                    <AddCategory addNewcategory={addNewcategory} handleNewCategory={setAddNewcategory} setUploading={setUploading}/>
                    <br/>

                    {editorial.banner.type == "" && 
                    <Grid container className={classes.formItem}>
                        <Grid item md={5} xs={12}>
                            <Typography variant="subtitle1">Choose a Banner </Typography>
                            <Button  color='primary' variant='contained'>
                                <input type="file" onChange={(e)=>{setLocalBanner(e.target.files[0])}}/>
                            </Button>
                        </Grid>
                        <Grid item md={7} xs={9} className={classes.formItem}>
                            {/* {editorial.banner && <Avatar variant='square' src={editorial.banner} className={classes.large} alt='Banner Image'/>} */}
                            {localBanner.type && localBanner.type.search("image") != -1 && <Button variant="contained" color="primary" endIcon={<DeleteIcon/>}
                            startIcon={<Avatar variant='square' src={URL.createObjectURL(localBanner)} className={classes.large} alt='Banner Image'/>} onClick={()=>{setLocalBanner({})}}></Button>}
                            
                            {localBanner.type && localBanner.type.search("video") != -1 && <Button variant="contained" color="primary" endIcon={<DeleteIcon/>}
                            startIcon={<video width="320" height="240" controls><source src={URL.createObjectURL(localBanner)} type={localBanner.type}/></video>} onClick={()=>{setLocalBanner({})}}></Button>}
                        
                        </Grid>
                    </Grid>}

                    <Grid container>
                        <Grid item xs={9} md={7}>
                    {/* {editorial.banner.type !="" && <span className={classes.formItem}>Firebase Vidoes hherer</span>} */}
                    {editorial.banner.type !="" && editorial.banner.type.search("image") != -1 && <Button className={classes.formItem} variant="contained" color="primary" endIcon={<DeleteIcon/>}
                            startIcon={<Avatar variant='square' src={editorial.banner.url} className={classes.large} alt='Banner Image'/>} onClick={()=>{deleteMediaForBanner(editorial.banner.url)}}></Button>}
                            
                    {editorial.banner.type !="" && editorial.banner.type.search("video") != -1 && <Button className={classes.formItem} variant="contained" color="primary" endIcon={<DeleteIcon/>}
                    startIcon={<video width="320" height="240" controls><source src={editorial.banner.url} type={localBanner.type}/></video>} onClick={()=>{deleteMediaForBanner(editorial.banner.url)}}></Button>}

                        </Grid>
                    </Grid>

                    <TextField type="text" multiline={true} rows={10} className={classes.formItem} label='Full Story' variant='filled' fullWidth required value={editorial.fullstory} onChange={(e)=>{setEditorial({...editorial,"fullstory":e.target.value})}}/>

                    <Grid container className={classes.formItem}>
                        <Grid item md={12} xs={12}>
                            <Typography variant="subtitle1">Upload all related media</Typography>
                            <Button  color='primary' variant='contained'>
                                <input type="file" onChange={handleLocalGalleryChange} multiple/>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.formItem}>
                        {editorial.gallery.map((media, index)=>
                            <>
                            {media.type.search("image") != -1  && <Grid item xs={9} md={6} key={`firebasegallery${index}`}>
                                <Button className={classes.formItem} variant="contained" color="primary" endIcon={<DeleteIcon/>}
                                startIcon={<Avatar variant='square' src={media.url} className={classes.large} alt='Banner Image'/>} onClick={()=>{deleteFromGallery(index)}}></Button>
                            </Grid>}
                            {media.type.search("video") != -1  && <Grid item xs={9} md={6} key={`firebasegallery${index}`}>
                            <Button className={classes.formItem} variant="contained" color="primary" endIcon={<DeleteIcon/>}
                            startIcon={<video width="320" height="240" controls><source src={media.url} type={media.type}/></video>} onClick={()=>{deleteFromGallery(index)}}></Button>
                            </Grid>}
                            </>
                        )}
                        {localGallery.map((media, index)=>
                            <>
                            {media.type.search("image") != -1  && <Grid item xs={9} md={6} key={`localGallery${index}`}>
                                <Button className={classes.formItem} variant="contained" color="primary" endIcon={<DeleteIcon/>}
                                startIcon={<Avatar variant='square' src={URL.createObjectURL(media)} className={classes.large} alt='Banner Image'/>} onClick={()=>{deleteFromLocalGallery(index)}}></Button>
                            </Grid>}
                            {media.type.search("video") != -1  && <Grid item xs={9} md={6} key={`localGallery${index}`}>
                                <Button className={classes.formItem} variant="contained" color="primary" endIcon={<DeleteIcon/>}
                                startIcon={<video width="320" height="240" controls><source src={URL.createObjectURL(media)} type={media.type}/></video>} onClick={()=>{deleteFromLocalGallery(index)}}></Button>
                            </Grid>}
                            </>
                         )} 
                    </Grid>

                    <Grid container justify='flex-end'>
                        {/* <Button color='primary' variant='contained' type='submit' className={classes.formItem}>Upload article</Button> */}
                        <div className={classes.uploadRoot}>
                            <div className={classes.uploadWrapper}>
                                <Button
                                variant="contained"
                                color="primary"
                                className={uploadButtonClassname}
                                disabled={uploading}
                                type="submit" 
                                >
                                Upload Article
                                </Button>
                                {uploading && <CircularProgress size={24} className={classes.uploadProgress} />}
                            </div>
                        </div>
                    </Grid>
                </form>

            </div>
        </Container>
        )}
        </>
    )
}