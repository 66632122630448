import {createMuiTheme} from '@material-ui/core/styles'

export const Inewstheme = createMuiTheme({
    palette:{
        background: {
            default: "rgb(241,241,241)",
        },
        primary:{
            main: "rgb(83,141,217)",
            light: "rgb(227,227,227)",
        },
    }
});