import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {firestore} from './Firebase'

import {useState} from 'react'

export default function AddCategory(props)
{
    const [category, setCategory] = useState('')
    const [uploading, setUploading] = useState(false)

    const Additem = async (e) =>{
        e.preventDefault()
        setUploading(true)
        props.setUploading(true)
        const doc = (await firestore.collection('categories').get()).docs[0] 
        const existingCategory = doc.data().type
        if(category != '' && existingCategory.indexOf(category) == -1)
        {
            await firestore.collection('categories').doc(doc.id).set({"type":[...existingCategory, category]})
            console.log("Category Added")
        }
        setUploading(false)
        props.handleNewCategory(false)
        props.setUploading(false)
    }

    return(
        <Dialog open={props.addNewcategory} onClose={()=>{props.handleNewCategory(false)}} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New Category"
            fullWidth
            value={category}
            onChange={(e)=>{setCategory(e.target.value)}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{props.handleNewCategory(false)}} color="primary" disabled={uploading}>
            Cancel
          </Button>
          <Button onClick={Additem} color="primary" disabled={uploading}>
            ADD
          </Button>
        </DialogActions>
      </Dialog>
    );
}