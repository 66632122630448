import { ThemeProvider, makeStyles, CssBaseline } from '@material-ui/core'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { Inewstheme } from './Theme'
import { AuthProvider } from './AuthContext'

import Home from './Home'
import LoginForm from './LoginForm'
import PrivateRoute from './PrivateRoute'
import EditArticle from './EditArticle'
import AddAdvertisement from './ManageAdvertisement'

import Headlines from './Headlines';
import NewsList from './NewsList';
import Layout from './Layout';

const useStyles = makeStyles((theme) => ({
  App: {
    height: '100vh',
  }
}))

function App() {
  const clases = useStyles();

  return (
    <ThemeProvider theme={Inewstheme}>
      <AuthProvider>
        <div className={clases.App}>
          <CssBaseline />

          <Router>
            <Switch>
              <PrivateRoute path="/edit_article/:article" component={EditArticle} />
              <PrivateRoute path="/add_article" component={EditArticle} />
              <PrivateRoute path="/NewsList/:category">
                <Layout>
                  <NewsList />
                </Layout>
              </PrivateRoute>
              <Route path="/login" component={LoginForm} />
              <PrivateRoute path="/manage_advertisement" component={AddAdvertisement}/>
              <PrivateRoute path="/">
                <Layout>
                  <Headlines />
                </Layout>
              </PrivateRoute>
            </Switch>
          </Router>
        </div>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
