import { Container, makeStyles, TextField, Button, Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Alert from '@material-ui/lab/Alert';

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import {useAuth} from './AuthContext'
import logo from './inews_logo.png'

const useStyles = makeStyles((theme)=>({
    scaffold:{
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]:{
            marginTop: theme.spacing(2),
        }  
    },
    paper:{
        marginTop: theme.spacing(3),
        padding: theme.spacing(5),
        backgroundColor: 'white',
        [theme.breakpoints.down('sm')]:{
            padding: theme.spacing(1),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(3),
        }
    },
    form:{
        marginTop: theme.spacing(1),
    },
    button:{
        marginTop:theme.spacing(2),
    },
    large: {
        width: theme.spacing(14),
        height: theme.spacing(14),
        [theme.breakpoints.down('sm')]:{
            width: theme.spacing(12),
            height: theme.spacing(12),
        }
    },
}))
export default function LoginForm()
{
    const classes = useStyles()
    const [creds,setCreds] = useState({'username':'','password':''})
    const [loginError, setLoginError] = useState(false)
    const [disableButton, setDisableButton] = useState(false)

    const {signin, currentUser} = useAuth();
    const history= useHistory();

    const handleSubmit = async (e)=>{
        e.preventDefault()
        setDisableButton(true)
        try {
            await signin(creds)
            history.push('/')

        } catch (error) {
            console.log(error)
            setLoginError(true)
        }
        setDisableButton(false)
    }

    useEffect(()=>{
        if(currentUser != null)
        {
            history.push("/");
        }
    },[]);

    return(
        <Container maxWidth='sm' className={classes.scaffold}>
            <Avatar variant='square' src={logo} className={classes.large} alt='Inews Logo'/>
            <div className={classes.paper}>
                {loginError && <Alert severity='error' className={classes.alert}>Invalid User name or password</Alert>}
                <form onSubmit={handleSubmit}  className={classes.form}>
                    <TextField value={creds.username} onChange={(e)=>{setCreds({...creds,'username':e.target.value})}} label='Username' margin='normal' variant='filled' type='email' fullWidth required/>
                    <TextField value={creds.password} onChange={(e)=>{setCreds({...creds,'password':e.target.value})}} label='Password' margin='normal' variant='filled' type='password' fullWidth required/>
                    <Grid container justify='flex-end'>
                        <Button color='primary' variant='contained' className={classes.button} type='submit' disabled={disableButton}>{(!disableButton)?<span>Login</span>:<span>Loading</span>}</Button>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}