import {Route, Redirect} from 'react-router-dom'
import {useAuth} from './AuthContext'

export default function PrivateRoute({ component: Component, ...rest })
{
    const {currentUser} = useAuth()

    return(
        <Route 
            {...rest}
            render={props => {
                return currentUser != null ? <Component {...props} /> : <Redirect to="/login" />
            }}
        />
    );
}