import React, { useContext, useEffect, useState } from 'react'
import {auth, storage} from './Firebase'

const AuthContext = React.createContext()

export function useAuth()
{
    return useContext(AuthContext)
}

export function AuthProvider({children})
{
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    
    function signin(creds){
        return auth.signInWithEmailAndPassword(creds.username,creds.password);
    }

    function signout()
    {
        return auth.signOut();
    }

    const AuthData = {
        "currentUser" : currentUser,
        "signin" : signin,
        "signout" : signout,
                    }

    useEffect(()=>{
        const unsubscribe = auth.onAuthStateChanged(firebase_user=>{
            setCurrentUser(firebase_user);
            setLoading(false);
        })

        return unsubscribe;
    },[])
    
    return(
        <AuthContext.Provider value={AuthData}>
            {!loading && children}
        </AuthContext.Provider>
    );
}